import { FolderData } from '../types';
import { careerItems } from '../components/folders/CareerFolder';
import { educationItems } from '../components/folders/EducationFolder';
import { songItems } from '../components/folders/SongFolder';
import { contentItems } from '../components/folders/ContentFolder';
import { contactItems } from '../components/folders/ContactFolder';
import { openThisItems } from '../components/folders/OpenThisFolder';

export const folders: FolderData = [
    { 
        name: "EXPERIENCE", 
        size: "4.5GB", 
        dateAdded: "03.13.2019 11:11PM", 
        kind: "FOLDER",
        items: careerItems,
    },
    { 
        name: "EDUCATION", 
        size: "3GB", 
        dateAdded: "08.22.2024 01:22AM", 
        kind: "FOLDER",
        items: educationItems,
    },
    { 
        name: "CONTACT", 
        size: "239MB", 
        dateAdded: "02.14.2022 10:31PM", 
        kind: "FOLDER", 
        items: contactItems,
    },
    { 
        name: "ON_REPEAT", 
        size: "567MB", 
        dateAdded: "08.22.2024 01:22AM", 
        kind: "FOLDER", 
        items: songItems,
    },
    { 
        name: "PAST_FEW_SUMMERS", 
        size: "137MB", 
        dateAdded: "08.22.2024 01:22AM", 
        kind: "FOLDER", 
        items: contentItems,
    },
    {
        name: "OPEN_THIS_FOLDER",
        size: "360GB",
        dateAdded: "12.21.2021 12:21PM",
        kind: "FOLDER",
        items: openThisItems,
    },
    {
        name: "2025",
        size: "0MB",
        dateAdded: "02.28.2024 11:45PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2026",
        size: "0MB",
        dateAdded: "03.01.2024 09:23AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2027",
        size: "0MB",
        dateAdded: "03.12.2024 04:56PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2028",
        size: "0MB",
        dateAdded: "03.07.2024 07:33AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2029",
        size: "0MB",
        dateAdded: "02.25.2024 01:19PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2030",
        size: "0MB",
        dateAdded: "03.18.2024 08:42PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2031",
        size: "0MB",
        dateAdded: "03.05.2024 05:11AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2032",
        size: "0MB",
        dateAdded: "03.09.2024 10:27PM",
        kind: "FOLDER",
        items: [],
    },
    // {
    //     name: "MAXIMILIAN_WALLER",
    //     size: "0MB",
    //     dateAdded: "05.15.2001 04:44AM",
    //     kind: "FOLDER",
    //     items: [],
    // },
    {
        name: "2033",
        size: "0MB",
        dateAdded: "03.14.2024 03:48AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2034",
        size: "0MB",
        dateAdded: "03.02.2024 06:59PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2035",
        size: "0MB",
        dateAdded: "03.11.2024 12:36PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2036",
        size: "0MB",
        dateAdded: "03.16.2024 09:14AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2037",
        size: "0MB",
        dateAdded: "03.04.2024 07:22PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2038",
        size: "0MB",
        dateAdded: "03.08.2024 01:47AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2039",
        size: "0MB",
        dateAdded: "03.03.2024 08:39PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2040",
        size: "0MB",
        dateAdded: "03.10.2024 06:15PM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2041",
        size: "0MB",
        dateAdded: "03.13.2024 11:52AM",
        kind: "FOLDER",
        items: [],
    },
    {
        name: "2042",
        size: "0MB",
        dateAdded: "03.06.2024 05:44PM",
        kind: "FOLDER",
        items: [],
    },
];