import React, { useState, useRef } from 'react';
import { Play, Pause } from 'lucide-react';

interface SongItemProps {
  name: string;
  dateAdded: string;
  audioUrl: string;
}

const SongItem: React.FC<SongItemProps> = ({ name, dateAdded, audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const togglePlay = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const updateProgress = () => {
    if (audioRef.current) {
      const value = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(value);
    }
  };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (audioRef.current && progressBarRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;
      const percentageClicked = (clickPosition / rect.width) * 100;
      const newTime = (audioRef.current.duration * percentageClicked) / 100;
      
      audioRef.current.currentTime = newTime;
      setProgress(percentageClicked);
    }
  };

  return (
    <div className="folder-item nested-item">
      <span className="name-column">
        {name}
      </span>
      <div className="player-column">
        <button onClick={togglePlay} className="play-button">
          {isPlaying ? <Pause size={16} /> : <Play size={16} />}
        </button>
        <div 
          ref={progressBarRef}
          className="progress-bar-container"
          onClick={handleProgressBarClick}
        >
          <div 
            className="progress-bar" 
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <span>{dateAdded}</span>
      <span>AUDIO</span>
      <audio 
        ref={audioRef}
        src={audioUrl}
        onTimeUpdate={updateProgress}
        onEnded={() => setIsPlaying(false)}
      />
    </div>
  );
};

export default SongItem;