import { BaseItem } from '../../types';

export const contactItems: BaseItem[] = [
    {
      name: "Email",
      size: "Ⅳ",
      dateAdded: "12.25.2011 02:19AM",
      kind: "LINK",
      url: "mailto:stuart.j.waller@vanderbilt.edu",
    },
    {
      name: "GitHub",
      size: "Ⅷ",
      dateAdded: "12.25.2011 02:20AM",
      kind: "LINK",
      url: "https://github.com/jonaswaller",
    },
    {
      name: "LinkedIn",
      size: "Ⅻ",
      dateAdded: "12.25.2011 02:21AM",
      kind: "LINK",
      url: "https://linkedin.com/in/jonas-waller",
    },
  ];