import { BaseItem } from '../../types';

export const contentItems: BaseItem[] = [
  {
    name: "9740015_Original.png",
    size: "2.3MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "IMAGE",
    contentUrl: "/content/000009740015_Original.png"
  },
  {
    name: "9760014_Original.png",
    size: "2.3MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "IMAGE",
    contentUrl: "/content/000009760014_Original.png"
  },
  {
    name: "9760015_Original.png",
    size: "2.3MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "IMAGE",
    contentUrl: "/content/000009760015_Original.png"
  },
  {
    name: "MVI_0131.MP4",
    size: "15.7MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "VIDEO",
    contentUrl: "/content/MVI_0131.MP4"
  },
  {
    name: "MVI_0137.MP4",
    size: "15.7MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "VIDEO",
    contentUrl: "/content/MVI_0137.MP4"
  }
];