import React, { useEffect, useRef, useState } from 'react';

interface FolderAnimationProps {
  children: React.ReactNode;
  index: number;
}

const FolderAnimation: React.FC<FolderAnimationProps> = ({ children, index }) => {
  const folderRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When the element becomes visible
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Once we've seen it, we don't need to observe anymore
          if (folderRef.current) {
            observer.unobserve(folderRef.current);
          }
        }
      },
      {
        // Trigger when just the top of the folder section enters view
        threshold: 0.01
      }
    );

    if (folderRef.current) {
      observer.observe(folderRef.current);
    }

    return () => {
      if (folderRef.current) {
        observer.unobserve(folderRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && folderRef.current) {
      folderRef.current.style.opacity = '0';
      folderRef.current.style.transform = 'translateX(-50px) skewX(-10deg)';
      
      const timeout = setTimeout(() => {
        if (folderRef.current) {
          folderRef.current.style.opacity = '1';
          folderRef.current.style.transform = 'translateX(0) skewX(0)';
          // Add a brief glow effect
          folderRef.current.style.textShadow = '0 0 10px #ff1493';
          folderRef.current.style.boxShadow = '0 0 10px rgba(255, 20, 147, 0.3)';
          
          // Remove glow effect after animation
          setTimeout(() => {
            if (folderRef.current) {
              folderRef.current.style.textShadow = 'none';
              folderRef.current.style.boxShadow = 'none';
            }
          }, 1000);
        }
      }, index * 10); // Faster sequence (50ms instead of 100ms)

      return () => clearTimeout(timeout);
    }
  }, [isVisible, index]);

  return (
    <div
      ref={folderRef}
      style={{
        opacity: '0',
        transform: 'translateX(-50px) skewX(-10deg)',
        transition: 'all 1.2s cubic-bezier(0.34, 1.56, 0.64, 1)', // Slower, more pronounced easing
        position: 'relative',
        zIndex: 1,
      }}
    >
      {children}
    </div>
  );
};

export default FolderAnimation;